<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import * as moment from 'moment';
import ScheduleAppointment from './schedule-appointment'

export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    FullCalendar,
    Layout,
    Multiselect,
    PageHeader,
    ScheduleAppointment
  },
  data() {
    return {
      title: "Calendar",
      SelectedLocation: false,
      items: [
         {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Calendar",
          active: true
        }
      ],
      options: [],
      location : null,
      calendarWeekends: true,
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrapPlugin,
        listPlugin
      ],
      themeSystem: "bootstrap",
      calendarEvents: [],
      startDate: null,
      endDate: null,
      date: "",
      createBtn: []
    };
  },
  validations: {
    event: {
      title: { required }
    }
  },
  mounted() {
    this.getLocation()
  },
  methods: {
    getLocation(){
        this.$axios.get(`location`)
        .then((response) => {
          let res  =  response.data.result
            res.location.forEach(items => {
                this.options.push({
                  name: items.name,
                  value: items.locationId
                })
                if (this.$store.getters.getStaffLocationId) {
                  if (items.locationId == this.$store.getters.getStaffLocationId) {
                    this.setLocation({
                      name: items.name,
                      value: items.locationId
                    })
                  }
                }
            });
        })
    },
    eventClicked(arg) {
      this.date = arg.event.start;
    },
    dateCliked(arg) {
      this.date = arg.date
    },
    renderDay(arg) {
      //console.log(arg)
      this.startDate = moment(arg.view.activeStart).format('YYYY-MM-DD')
      this.endDate =  moment(arg.view.activeEnd).format('YYYY-MM-DD')

      this.getListEvent()
    },
    setLocation(selected) {
      this.location = selected
      this.getListEvent()
    },
    getListEvent() {
      this.$axios.get(`appointment`, {
        params: {
          page: 1,
          limit: 1000,
          locationId: (this.location)? this.location.value : null,
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.endDate).format('YYYY-MM-DD'),
        }
      })
      .then( response => {
        const result = response.data.result.appointments
        console.log(result)
        this.calendarEvents = []
        result.forEach( data => {
          let color;
          switch(data.status) {
            case 'scheduled':
              color = 'bg-warning'
              break;
            case 'confirmed':
              color = 'bg-success'
              break;
            case 'canceled':
              color = 'bg-danger'
              break;
            case 'completed':
              color = 'bg-primary'
              break;
            case 'in progress':
              color = 'bg-info'
              break;
            default:
              color = 'bg-secondary'
              break;
          }
          this.calendarEvents.push({
            title: moment(data.time, "HH:mm:ss").format('HH:mm') + ' ' + data.user,
            start: moment(data.date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            className: color
          })
        })
        
      }).catch( error => {
        console.log(error)
      })
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="for-selectedLocation">
      <multiselect
        v-model="location"
        deselect-label="Can't remove this value"
        track-by="name"
        label="name"
        placeholder="Filter by location"
        :options="options"
        :searchable="true"
        :allow-empty="false"
        :disabled="this.$store.getters.getStaffLocationId"
        @select="setLocation"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <strong>{{ option.name }}</strong>
        </template>
      </multiselect>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <!-- Calendar -->
              <FullCalendar
                ref="fullCalendar"
                default-view="dayGridMonth"
                :header="{ right: 'today prev,next', center: 'title', left: this.SelectedLocation, }"
                :editable="true"
                :droppable="true"
                :plugins="calendarPlugins"
                :events="calendarEvents"
                :weekends="calendarWeekends"
                :theme-system="themeSystem"
                @datesRender="renderDay($event)"
                @eventClick="eventClicked($event)"
                @dateClick="dateCliked($event)"
              />
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <ScheduleAppointment :date="date.toString()" :location="location" @close="date = ''"></ScheduleAppointment>
  </Layout>
</template>