<script>
import DetailAppointment from "../appointment/detail-appointment";
import * as moment from "moment";
/**
 * Advanced table component
 */
export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  components: { DetailAppointment },
  data() {
    return {
      showAppointmentId: null,
      rooms: [],
      appointments: [],
      timeStart: '08:00:00',
      timeEnd: '17:00:00',
      clinic: null,
      schedules: []
    };
  },
  computed: {
    modalTitle() {
      return `${moment(this.date).format("DD MMMM YYYY")}`;
    },
    timeRange: {
      get: function () {
        const openHour = moment(this.timeStart, 'HH:mm:ss')
        const closeHour = moment(this.timeEnd, 'HH:mm:ss')

        const list = []
        let loop = openHour
        while(moment(loop).isSameOrBefore(closeHour)) {

          let time = moment(loop).format('HH:mm:ss')

          list.push(time)
          
          loop = moment(loop).add(15, 'minutes')
        }

        return list
      },
      set: function (start, end) {
        this.timeStart = start
        this.timeEnd = end
      }
    },
    canCreateAppointment() {
      const now = moment()
      if (moment(this.date).isSameOrAfter(now, 'day')) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    date(newVal) {
      if (newVal !== null && newVal !== "") {
        this.$bvModal.show("modal-list-appointment");
        this.fetchAppointment()
        this.setWorkHour()
      }
    },
    location() {
      this.findLocation()
      this.findRoom()
      this.setWorkHour()
    },
    clinic() {
      
    }
  },
  mounted() {
    this.findRoom()
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setWorkHour() {
      if (this.date && this.clinic) {
        const day = moment(this.date).format("dddd")
        const workhour = this.clinic.workhour[day.toLowerCase()]
        this.timeStart = workhour.open
        this.timeEnd = workhour.close
      } else {
        this.timeStart = '08:00:00'
        this.timeEnd = '17:00:00'
      }
    },
    findLocation() {
      if (this.location) {
        this.$axios(`location/${this.location.value}`)
          .then(response => {
            const location = response.data.result.location
            this.clinic = location
          })
      }
    },
    findRoom() {
      this.$axios
        .get(`room`, {
          params: {
            location: (this.location)? this.location.value : null,
            page: 1,
            limit: 1000
          }
        })
        .then((response) => {
          let result = response.data.result.room;
          this.rooms = result
        });
    },
    fetchAppointment() { 
      let form = new FormData()
      form.append('date', moment(this.date).format("YYYY-MM-DD"))
      if (this.location) {
        form.append('locationId', this.location.value)
      }
      this.$axios.post(`appointment/calendar/schedule/by-date`, form)
        .then((response) => {
          let result = response.data.result.appointments;
          this.appointments = result

          let schedules = []
          this.timeRange.forEach( (time, timeIndex) => {
            schedules[timeIndex] = []
          })
          this.timeRange.forEach( (time, timeIndex) => {
            this.rooms.forEach( (room, roomIndex) => {
              schedules[timeIndex][roomIndex] = this.appointments.find( app => app.time == time && app.room == room.name)
            })
          })
          
          this.schedules = [...schedules]
        });
    },
    statusColor(statusAppointment) {
      if (statusAppointment == 'scheduled') {
        return 'warning'
      } else if (statusAppointment == 'confirmed') {
        return 'success'
      } else if (statusAppointment == 'canceled') {
        return 'danger'
      } else if (statusAppointment == 'completed') {
        return 'primary'
      } else if (statusAppointment == 'in progress') {
        return 'info'
      } else {
        return 'secondary'
      }
    },
    createAppointment(time, room) {
      this.$router.push({ path: '/appointment/create', 
        query: { 
          location: this.location.value,
          date: moment(this.date).format('YYYY-MM-DD'),
          room: room.roomId,
          time: time 
        }
      })
    }
  },
};
</script>

<template> 
  <b-modal
      id="modal-list-appointment"
      :title="modalTitle"
      size="xl"
      title-class="text-black font-18"
      hide-footer
      @close="closeModal"
      no-close-on-backdrop
    >
    <div class="row">
      <div class="col-12">
        <!-- Table -->
        <b v-if="location === null">Please select location first, so you can create appointment from selected room</b>
        <b-table-simple sticky-header hover bordered responsive>
          <b-thead>
            <b-tr>
              <b-th sticky-column style="width: 10%">Times</b-th>  
              <b-th v-for="(room, index) in rooms" :key="index">{{ room.name }}</b-th>
            </b-tr>     
          </b-thead>
          <b-tbody>
            <template v-for="(time, index) in timeRange">
              <b-tr :key="index">
                <b-th sticky-column>{{ time }}</b-th>
                <template v-if="schedules.length">
                  <template v-for="(room, zindex) in rooms">
                    <template v-if="schedules[index][zindex] !== undefined">
                      <b-th :key="zindex">
                        <b-button :variant="statusColor(schedules[index][zindex].status)"  v-b-tooltip.hover title="Detail Appointment" size="sm" @click="showAppointmentId = schedules[index][zindex].appointmentId">
                          {{ schedules[index][zindex].user }}
                        </b-button>
                      </b-th>
                    </template>
                    <template v-else>
                      <b-th :key="zindex">
                        <template v-if="location && canCreateAppointment">
                          <b-button variant="primary" size="sm" @click="createAppointment(time,room)">create appointment</b-button>
                        </template>
                        <template v-else>-</template>
                      </b-th>
                    </template>
                  </template>
                </template>
              </b-tr>
            </template>
          </b-tbody>  
         </b-table-simple>
      </div>
    </div>

    <detail-appointment :id="showAppointmentId" @close="showAppointmentId = null"></detail-appointment>
  </b-modal>
</template>